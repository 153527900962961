import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Typography,
  DatePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import displayErrors from "../../utils/displayErrors";
import {
  clearErrorEdit,
  clearSuccessEdit,
  patchCorrections,
} from "../../store/corrections";
import {
  getTypeDropDownSelector,
  getErrorEdits,
} from "../../store/corrections/selectors";
import { SuccessModalSide } from "../SuccessModalSide";
import TextArea from "antd/es/input/TextArea";
import "react-phone-input-2/lib/style.css";
import {
  throwFormError,
  convertDateCorrections,
  resetDateFilterBefore,
} from "../../utils/utils";
import { getStudioData } from "../../store/studios/selectors";
import { getStaffDropDownSelector } from "../../store/staff/selectors";

export const EditCorrectionsModal = ({
  openCorrectionsEdit,
  setOpenCorrectionsEdit,
}) => {
  const [formEditCorrections] = Form.useForm();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const studio = useSelector(getStudioData);
  const error = useSelector(getErrorEdits);
  const masterList = useSelector(getStaffDropDownSelector);
  const typeList = useSelector(getTypeDropDownSelector);
  const [selectedType, setSelectedType] = useState("");
  const [openSuccessEdit, setOpenSuccessEdit] = useState(false);

  useEffect(() => {
    if (openCorrectionsEdit?.record) {
      formEditCorrections.setFields([
        { name: "comment", value: openCorrectionsEdit?.record?.comment || "" },
        {
          name: "corr_type",
          value: openCorrectionsEdit?.record?.corr_type || "",
        },
        {
          name: "loyal_cost",
          value: openCorrectionsEdit?.record?.loyal_cost || "",
        },
        {
          name: "cost",
          value: openCorrectionsEdit?.record?.cost || "",
        },
        {
          name: "master",
          value: {
            value: openCorrectionsEdit?.record?.master?.id,
            label: openCorrectionsEdit?.record?.master?.name,
          },
        },
        {
          name: "pub_date",
          value: resetDateFilterBefore(openCorrectionsEdit?.record?.pub_date),
        },
      ]);
    }
  }, [formEditCorrections, openCorrectionsEdit?.record]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <Drawer
        styles={{
          body: { padding: width > 640 ? "0 64px 20px" : "0 24px 10px" },
        }}
        open={openCorrectionsEdit?.open}
        onCancel={() => {
          dispatch(clearErrorEdit());
          setOpenCorrectionsEdit({ open: false });
          dispatch(clearSuccessEdit());
        }}
        onClose={() => {
          dispatch(clearErrorEdit());
          setOpenCorrectionsEdit({ open: false });
          dispatch(clearSuccessEdit());
        }}
        footer={null}
        title={
          <Typography.Title style={{ margin: 0 }} level={3}>
            Редактировать коррекцию
          </Typography.Title>
        }
        width={500}
        maskClosable={true}
      >
        {openSuccessEdit ? (
          <SuccessModalSide
            close={() => {
              dispatch(clearErrorEdit());
              setOpenCorrectionsEdit({ open: false });
              dispatch(clearSuccessEdit());
              setOpenSuccessEdit(false);
            }}
            title="Коррекция успешно изменена!"
          />
        ) : (
          // {loader && <Spin size="large" />}
          <>
            <Form
              style={{ marginTop: "20px" }}
              layout="vertical"
              form={formEditCorrections}
              // initialValues={{}}
              onFinish={(data) => {
                if (typeof data.master === "object") {
                  data.master = openCorrectionsEdit?.record?.master?.id;
                }
                data.pub_date = convertDateCorrections(data.pub_date);
                dispatch(
                  patchCorrections({
                    studio_y_id: studio?.y_id,
                    id: openCorrectionsEdit.record.id,
                    params: data,
                  })
                ).then((state) => {
                  if (state?.payload?.errors) {
                    throwFormError(formEditCorrections, state?.payload);
                  } else {
                    setOpenSuccessEdit(true);
                  }
                });
              }}
            >
              {error && displayErrors(error)}

              <Form.Item
                name="master"
                rules={[
                  {
                    required: true,
                    message: "Необходимо выбрать сотрудника",
                  },
                ]}
                label="Сотрудник"
              >
                <Select
                  style={{ borderRadius: 2 }}
                  optionFilterProp="label"
                  showSearch={true}
                  allowClear={true}
                  options={masterList?.results?.map((option) => {
                    return {
                      value: option.id,
                      label: option.y_name,
                    };
                  })}
                  placeholder="Выберите сотрудник"
                />
              </Form.Item>

              <Form.Item
                name="pub_date"
                label="Дата коррекции"
                rules={[
                  {
                    required: true,
                    message: "Необходимо выбрать дату коррекции",
                  },
                ]}
              >
                <DatePicker
                  cellWidth={10}
                  timeColumnWidth={10}
                  presetsMaxWidth={10}
                  presetsWidth={10}
                  style={{ width: "100%", borderRadius: 2 }}
                  placeholder="Выберите дату коррекции"
                />
              </Form.Item>

              <Form.Item
                name="corr_type"
                rules={[
                  {
                    required: true,
                    message: "Необходимо выбрать тип коррекции",
                  },
                ]}
                label="Тип коррекции"
              >
                <Select
                  style={{ borderRadius: 2 }}
                  options={typeList?.map((option) => {
                    return {
                      value: option.value,
                      label: option.label,
                    };
                  })}
                  value={selectedType}
                  onChange={setSelectedType}
                  placeholder="Выберите тип коррекции"
                />
              </Form.Item>

              <Form.Item
                name="cost"
                label="Сумма коррекции"
                rules={[
                  {
                    required: true,
                    message: "Необходимо ввести сумму коррекции",
                  },
                ]}
              >
                <Input
                  placeholder="Введите сумму коррекции"
                  style={{ borderRadius: 2 }}
                />
              </Form.Item>

              <Form.Item name="comment" label="Комментарий">
                <TextArea
                  placeholder="Описание"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                  style={{ borderRadius: 2 }}
                />
              </Form.Item>

              <Row gutter={24}>
                <Col span={12}>
                  <Button
                    style={{ width: "100%", borderRadius: 2 }}
                    onClick={() => {
                      setOpenCorrectionsEdit({ open: false });
                      dispatch(clearErrorEdit());
                    }}
                  >
                    Отмена
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: 2,
                      backgroundColor: "#76CEC9",
                    }}
                    htmlType="submit"
                    type="primary"
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Drawer>
    </>
  );
};
