import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Upload,
  message,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import displayErrors from "../../utils/displayErrors";
import { getUserData } from "../../store/user/selectors";
import {
  patchFail,
  getTypeDropDown,
  clearErrorEdit,
  clearSuccessEdit,
  deleteFailPhoto,
} from "../../store/fails";
import {
  getTypeDropDownSelector,
  getErrorEdits,
} from "../../store/fails/selectors";
import { SuccessModalSide } from "../SuccessModalSide";
import { convertDate, resetDateFilterBefore } from "../../utils/utils";
import TextArea from "antd/es/input/TextArea";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  throwFormError,
  convertToLocalDate,
  // resetDateFilterBefore,
} from "../../utils/utils";
import { postClient, clearClient, clearError } from "../../store/clients";
import { getStudioRecords, getServiceRecord } from "../../store/studios";
import {
  getStudioData,
  getStudioRecordsDropDown,
  getServiceRecordsDropDown,
} from "../../store/studios/selectors";
import { getClientData, getError } from "../../store/clients/selectors";

export const EditFailsModal = ({ openFailsEdit, setOpenFailsEdit }) => {
  const [formEditFails] = Form.useForm();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [imageFile, setImageFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const studio = useSelector(getStudioData);
  const userData = useSelector(getUserData);
  const clientData = useSelector(getClientData);
  const error = useSelector(getErrorEdits);
  const errorClient = useSelector(getError);
  const typeList = useSelector(getTypeDropDownSelector);
  const studioRecordsList = useSelector(getStudioRecordsDropDown);
  const serviceRecordsList = useSelector(getServiceRecordsDropDown);
  const [number, setNumber] = useState("");
  const [selectedType, setSelectedType] = useState([]);
  const [selectedFail, setSelectedFail] = useState(null);
  const [selectedRepair, setSelectedRepair] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [openSuccessEdit, setOpenSuccessEdit] = useState(false);

  const handleChange = ({ fileList: newFileList }) => {
    setImageFile(newFileList);
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
          fontSize: 9,
        }}
      >
        Прикрепите фотографию от клиента
      </div>
    </button>
  );

  useEffect(() => {
    if (openFailsEdit?.record?.client?.y_phone?.length > 10) {
      dispatch(
        postClient({
          studio_y_id: studio?.y_id,
          y_phone: number || openFailsEdit?.record?.client?.y_phone,
        })
      );
      dispatch(getTypeDropDown(studio?.y_id));
      dispatch(
        getStudioRecords({
          studio_y_id: studio?.y_id,
          y_phone: number || openFailsEdit?.record?.client?.y_phone,
        })
      );
    } else {
      dispatch(clearClient());
      dispatch(clearError());
    }
  }, [openFailsEdit?.record?.client?.y_phone]);

  useEffect(() => {
    dispatch(clearErrorEdit());
    if (openFailsEdit?.record) {
      const transformedFileList =
        openFailsEdit?.record?.photos?.map((photo) => ({
          uid: photo.id,
          name: photo.caption,
          status: "done",
          url: photo.photo,
        })) || [];

      setImageFile(transformedFileList);
      formEditFails.setFields([
        {
          name: "repair_record",
          value:
            `${
              convertDate(openFailsEdit?.record?.repair_record?.y_datetime) ||
              ""
            }${
              openFailsEdit?.record?.repair_record?.y_services[0]?.y_title
                ? `, ${openFailsEdit?.record?.repair_record?.y_services[0]?.y_title}`
                : ""
            }` ?? "",
        },
        { name: "comment", value: openFailsEdit?.record?.comment || "" },
        {
          name: "repair_cost",
          value: openFailsEdit?.record?.repair_cost || "",
        },
        {
          name: "loyal_cost",
          value: openFailsEdit?.record?.loyal_cost || "",
        },
        {
          name: "fail_record",
          value:
            `${convertDate(openFailsEdit?.record?.fail_record?.y_datetime)}` ||
            "",
        },
        {
          name: "service",
          value: `${openFailsEdit?.record?.service?.y_title}` || "",
        },
        {
          name: "fail_type",
          value:
            openFailsEdit?.record?.fail_type.map((item) => {
              return {
                value: item.id || "",
                label: item.name || "",
              };
            }) || [],
        },
        {
          name: "request_datetime",
          value: resetDateFilterBefore(openFailsEdit?.record?.request_datetime),
        },
      ]);
    }
  }, [formEditFails, openFailsEdit?.record]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <Drawer
        styles={{
          body: { padding: width > 640 ? "0 64px 20px" : "0 24px 10px" },
        }}
        open={openFailsEdit?.open}
        onCancel={() => {
          dispatch(clearErrorEdit());
          setOpenFailsEdit({ open: false });
          dispatch(clearSuccessEdit());
        }}
        onClose={() => {
          dispatch(clearErrorEdit());
          setOpenFailsEdit({ open: false });
          dispatch(clearSuccessEdit());
        }}
        footer={null}
        title={
          <Typography.Title style={{ margin: 0 }} level={3}>
            Редактировать жалобу
          </Typography.Title>
        }
        width={500}
        maskClosable={true}
      >
        {openSuccessEdit ? (
          <SuccessModalSide
            close={() => {
              dispatch(clearErrorEdit());
              setOpenFailsEdit({ open: false });
              dispatch(clearSuccessEdit());
              setOpenSuccessEdit(false);
            }}
            title="Жалоба успешно изменена!"
          />
        ) : (
          // {loader && <Spin size="large" />}
          <>
            <Form
              style={{ marginTop: "20px" }}
              layout="vertical"
              form={formEditFails}
              // initialValues={{}}
              onFinish={async (data) => {
                data.repair_record =
                  selectedRepair || openFailsEdit?.record?.repair_record?.id;
                data.fail_record =
                  selectedFail || openFailsEdit?.record?.fail_record?.id;
                // data.request_datetime = convertDateISO(data.request_datetime);
                data.service =
                  selectedService || openFailsEdit?.record?.service?.id;

                let formData = new FormData();
                for (let key of Object.keys(data)) {
                  if (data[key] !== undefined && data[key] !== "") {
                    if (key !== "fail_type" && key !== "photos") {
                      formData.append(`${key}`, data[key]);
                    }
                  }
                }
                data.fail_type.forEach((type) => {
                  formData.append("fail_type", type?.value || type);
                });

                imageFile.map((file) => {
                  if (file.originFileObj) {
                    formData.append("photos", file.originFileObj);
                  }
                });

                formData.append(`created_by`, userData?.user?.id);
                formData.append(`client`, clientData?.id);
                formData.append(`studio`, studio?.id);

                dispatch(
                  patchFail({
                    studio_y_id: studio?.y_id,
                    id: openFailsEdit.record.id,
                    formData,
                  })
                ).then((state) => {
                  if (state?.payload?.errors) {
                    throwFormError(formEditFails, state?.payload);
                  } else {
                    setOpenSuccessEdit(true);
                  }
                });
              }}
            >
              {error && displayErrors(error)}
              <Typography.Title level={5}>
                Первичная информация жалобы:
              </Typography.Title>
              <Row
                style={{
                  flexWrap: "nowrap",
                  gap: 10,
                  marginBottom: "24px",
                }}
              >
                <Col>
                  <PhoneInput
                    inputStyle={{ width: 205 }}
                    value={openFailsEdit?.record?.client?.y_phone}
                    country={"ru"}
                    onChange={(num) => setNumber(num)}
                  />
                </Col>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "90%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {(clientData || errorClient) && (
                    <Tooltip
                      color="#FFFFFF"
                      title={
                        <span style={{ color: "#4B4F55" }}>
                          {clientData ? clientData?.y_name : "клиент не найден"}
                        </span>
                      }
                    >
                      <Typography.Title
                        level={5}
                        style={{
                          margin: 0,
                        }}
                      >
                        {clientData ? clientData?.y_name : "клиент не найден"}
                      </Typography.Title>
                    </Tooltip>
                  )}
                </Col>
              </Row>

              {/* <Form.Item
                name="request_datetime"
                label="Дата и время обращения клиента"
                rules={[
                  {
                    required: true,
                    message: "Необходимо выбрать дату",
                  },
                ]}
              >
                <DatePicker
                  disabled={
                    openFailsEdit?.record?.client?.y_phone < 11 || !clientData
                  }
                  value={convertDate(openFailsEdit?.record?.request_datetime)}
                  style={{ width: "100%", borderRadius: 2 }}
                  placeholder="Выберите дату и время"
                  showTime
                />
              </Form.Item> */}

              <Form.Item
                name="fail_type"
                rules={[
                  {
                    required: true,
                    message: "Необходимо выбрать тип жалобы",
                  },
                ]}
                label="Тип жалобы"
              >
                <Select
                  mode="multiple"
                  disabled={
                    openFailsEdit?.record?.client?.y_phone < 11 || !clientData
                  }
                  options={typeList?.results?.map((option) => {
                    return {
                      value: option.id,
                      label: option.name,
                    };
                  })}
                  value={selectedType}
                  onChange={setSelectedType}
                  placeholder="Выберите одну или несколько"
                />
              </Form.Item>

              <Form.Item
                name="fail_record"
                label="Жалоба на запись"
                rules={[
                  {
                    required: true,
                    message: `Необходимо выбрать запись`,
                  },
                ]}
              >
                <Select
                  disabled={
                    openFailsEdit?.record?.client?.y_phone < 11 || !clientData
                  }
                  options={studioRecordsList?.results?.map((option) => {
                    return {
                      value: option?.id,
                      label: `${convertToLocalDate(option?.y_datetime)}`,
                    };
                  })}
                  value={selectedFail}
                  onChange={(item) => {
                    setSelectedFail(item);
                    dispatch(
                      getServiceRecord({
                        studio_y_id: studio?.y_id,
                        id: item,
                      })
                    );
                  }}
                  placeholder="Выберите запись"
                />
              </Form.Item>

              <Form.Item
                name="service"
                label="Услуга"
                rules={[
                  {
                    required: true,
                    message: `Выберите услугу`,
                  },
                ]}
              >
                <Select
                  disabled={
                    openFailsEdit?.record?.client?.y_phone < 11 ||
                    !clientData ||
                    studioRecordsList?.results?.y_services
                  }
                  options={
                    serviceRecordsList?.y_services?.map((option) => {
                      return {
                        value: option.id,
                        label: option?.y_title,
                      };
                    }) || []
                  }
                  value={selectedService}
                  onChange={setSelectedService}
                  placeholder="Выберите услугу"
                />
              </Form.Item>
              <Form.Item name="photos" label="Фото от клиента">
                <Upload
                  disabled={
                    openFailsEdit?.record?.client?.y_phone < 11 || !clientData
                  }
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={true}
                  beforeUpload={() => false}
                  fileList={imageFile}
                  accept=".jpeg,.png,.svg+xml,.jpg"
                  onChange={handleChange}
                  onRemove={(evt) =>
                    dispatch(
                      deleteFailPhoto({
                        studio_y_id: studio?.y_id,
                        id: openFailsEdit?.record?.id,
                        uid: evt?.uid,
                      })
                    )
                  }
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
              <Form.Item name="comment" label="Комментарий">
                <TextArea
                  disabled={
                    openFailsEdit?.record?.client?.y_phone < 11 || !clientData
                  }
                  placeholder="Опишите жалобу клиента"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                  style={{ borderRadius: 2 }}
                />
              </Form.Item>

              <Typography.Title level={5}>Обработка жалобы:</Typography.Title>

              <Form.Item name="repair_cost" label="Стоимость переделки">
                <Input
                  disabled={
                    openFailsEdit?.record?.client?.y_phone < 11 || !clientData
                  }
                  placeholder="Введите стоимость"
                  style={{ borderRadius: 2 }}
                />
              </Form.Item>

              <Form.Item name="loyal_cost" label="Сумма скидки">
                <Input
                  disabled={
                    openFailsEdit?.record?.client?.y_phone < 11 || !clientData
                  }
                  placeholder="Введите сумму скидки"
                  style={{ borderRadius: 2 }}
                />
              </Form.Item>

              <Form.Item name="repair_record" label="Запись переделки">
                <Select
                  disabled={
                    openFailsEdit?.record?.client?.y_phone < 11 || !clientData
                  }
                  options={studioRecordsList?.results?.map((option) => {
                    return {
                      value: option?.id,
                      label: `${convertToLocalDate(option?.y_datetime)} ${
                        option?.y_services[0]?.y_title
                          ? `, ${option?.y_services[0]?.y_title}`
                          : ""
                      }`,
                    };
                  })}
                  // value={selectedRepair}
                  onChange={setSelectedRepair}
                  placeholder="Выберите запись"
                />
              </Form.Item>

              <Row gutter={24}>
                <Col span={12}>
                  <Button
                    style={{ width: "100%", borderRadius: 2 }}
                    onClick={() => {
                      setOpenFailsEdit({ open: false });
                      dispatch(clearClient());
                      dispatch(clearErrorEdit());
                    }}
                  >
                    Отмена
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: 2,
                      backgroundColor: "#76CEC9",
                    }}
                    htmlType="submit"
                    type="primary"
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Drawer>
    </>
  );
};
