import { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import {
  Input,
  Button,
  Table,
  Badge,
  Select,
  Space,
  Spin,
  Row,
  Col,
  Tooltip,
  Avatar,
} from "antd";
import { DatePickerPopover } from "../../components/DatePickerPopover";
import { ModalComponent } from "../../components/ModalComponent";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import {
  CloseOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  isInclude,
  formatDateString,
  convertDateToISO861,
  selectDropdown,
  updateFilters,
  updateFiltersArr,
} from "../../utils/utils";
import filters from "../../images/filters.svg";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import {
  getStudioData,
  getStudioLoading,
  getErrorStudio,
} from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getPositionDropDown,
  deleteStaff,
  getStaffs,
  clearErrorDelete,
  clearSuccess,
  clearError,
} from "../../store/staff";
import {
  getPositionDataSelector,
  getStaffsDataSelector,
  getSuccessDelete,
  getSuccess,
  getStaffNextPage,
  getStaffLoading,
  getError,
} from "../../store/staff/selectors";
import displayErrors from "../../utils/displayErrors";
import { useNavigate } from "react-router-dom";

export const StaffPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const errorStudio = useSelector(getErrorStudio);
  const staffsData = useSelector(getStaffsDataSelector);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedFired, setSelectedFired] = useState([]);
  const [includePosition, setIncludePosition] = useState(true);
  const [includeFired, setIncludeFired] = useState(true);
  const [includeChain, setIncludeChain] = useState(true);
  const [fileSearch, setFileSearch] = useState("");
  const [filtersArray, setFiltersArray] = useState([]);
  const [openFiltersRow, setOpenFiltersRow] = useState(false);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const isLoadingStudio = useSelector(getStudioLoading);
  const isLoadingStaff = useSelector(getStaffLoading);
  const positionList = useSelector(getPositionDataSelector);
  const successDelete = useSelector(getSuccessDelete);
  const openInfo = useSelector(getOpenInfoSelector);
  const [openStaffDelete, setOpenStaffDelete] = useState(false);
  const [openStaffEdit, setOpenStaffEdit] = useState(false);
  const success = useSelector(getSuccess);
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getStaffNextPage);
  const lastItem = useRef();
  const observer = useRef();

  const columns = [
    createColumn(
      "Сеть",
      "y_chain",
      (v) => {
        return v?.y_title;
      },
      70
    ),
    createColumn(
      "Сотрудник",
      undefined,
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              src={row?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "170px",
                  display: "flex",
                }}
              >
                {row?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Нанят / уволен",
      undefined,
      (_, row) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
              {formatDateString(row?.hiring_date)}
              {row?.hiring_date && row?.y_dismissal_date && "/"}
            </span>
            <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
              {formatDateString(row?.y_dismissal_date)}
            </span>
          </Space>
        );
      },
      100
    ),
    createColumn("Рейтинг", "y_rating", undefined, 100),
    createColumn(
      "Статус",
      "y_fired",
      (v) => {
        return !v ? "Работает" : "Уволен";
      },
      100
    ),
    createColumn("Схема премирования", "repair_record", undefined, 150),
    createColumn(
      "Пользователь",
      "user",
      (v) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ textWrap: "nowrap" }}>
              {v?.external_data?.y_name}
            </span>
            <span
              style={{
                color: "#8B8B8B",
                fontSize: 12,
                lineHeight: "14.52px",
                textWrap: "nowrap",
              }}
            >
              {v?.external_data?.y_phone && `+${v?.external_data?.y_phone}`}
            </span>
          </Space>
        );
      },
      100
    ),
    createColumn(
      "Работает официально",
      "is_official",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Официально" />
        ) : (
          <img src={iconNotSuccessful} alt="Не официально" />
        );
      },
      100
    ),
    createColumn(
      "Локальный сотрудник",
      "is_local",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Локальный" />
        ) : (
          <img src={iconNotSuccessful} alt="Не локальный" />
        );
      },
      100
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>Удалить сотрудника</span>}
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenStaffDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      50
    ),
  ];

  const fired = [
    { name: "Уволенные", bol: true },
    { name: "Не уволенные", bol: false },
  ];

  const chain = [
    { name: "Сетевые сотрудники", bol: true },
    { name: "Не сетевые сотрудники", bol: false },
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  const changeArrayFilters = ({ key, value, text, title }) => {
    const isIncludes = (el, filterName) => {
      return el.key.includes(filterName) !== key.includes(filterName);
    };

    let filtersResult = filtersArray.filter((i) => {
      return isIncludes(i, "position");
    });

    setFiltersArray(
      value || value === 0
        ? [
            ...filtersResult,
            { key: key, value: value, text: text, title: title },
          ]
        : filtersResult
    );
  };

  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      setDateAfterFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateBeforeFilter) {
        setViewDataFilter(
          e?.$d.toLocaleDateString("ru-RU") + " - " + dateBeforeFilter
        );
      } else {
        setViewDataFilter(e?.$d.toLocaleDateString("ru-RU") + " - ");
      }
    } else {
      if (dateAfterFilter) {
        setViewDataFilter(dateBeforeFilter);
      }
      setDateAfterFilter(null);
    }
  };

  const handleChangeDateBefore = (e) => {
    if (e?.$d) {
      setDateBeforeFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateAfterFilter) {
        setViewDataFilter(
          dateAfterFilter + " - " + e?.$d.toLocaleDateString("ru-RU")
        );
      } else {
        setViewDataFilter(" - " + e?.$d.toLocaleDateString("ru-RU"));
      }
    } else {
      if (dateBeforeFilter) {
        setViewDataFilter(dateAfterFilter);
      }
      setDateBeforeFilter(null);
    }
  };

  const fetchData = (pagination = false) => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    if (dateAfterFilter || dateBeforeFilter) {
      filters = {
        start_date: convertDateToISO861(dateAfterFilter),
        end_date: convertDateToISO861(dateBeforeFilter),
      };
    }

    if (pagination && nextPage) {
      const urlParams = new URLSearchParams(nextPage.split("?")[1]);
      limitValue = Number(urlParams.get("limit")) + 25;
    }

    dispatch(
      getStaffs({
        studio_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        filters: filters,
        filtersArray: filtersArray,
        // cursor: pagination ? offsetValue : null,
        limit: pagination ? limitValue : null,
        pagination: pagination,
      })
    );
  };

  const clearFilters = () => {
    setSelectedPosition([]);
    setSelectedChain(null);
    setSelectedFired(null);
    setFileSearch("");
    setIncludePosition(true);
    setIncludeChain(true);
    setIncludeFired(true);
    setViewDataFilter(null);
    setDateBeforeFilter(null);
    setDateAfterFilter(null);
    setFiltersArray([]);
  };

  useEffect(() => {
    dispatch(clearErrorStudio());
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if (studioData) {
      dispatch(getPositionDropDown(studioData?.y_id));
    }
  }, [studioData?.y_id]);

  useEffect(() => {
    updateFiltersArr(
      "position",
      includePosition,
      selectedPosition,
      setFiltersArray,
      filtersArray
    );
  }, [includePosition, selectedPosition]);

  useEffect(() => {
    updateFilters(
      "is_fired",
      includeFired,
      selectedFired,
      setFiltersArray,
      filtersArray
    );
  }, [includeFired, selectedFired]);

  useEffect(() => {
    updateFilters(
      "y_is_chain",
      includeChain,
      selectedChain,
      setFiltersArray,
      filtersArray
    );
  }, [includeChain, selectedChain]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    dateAfterFilter,
    dateBeforeFilter,
    filtersArray,
    studioData,
    success,
    successDelete,
  ]);

  useEffect(() => {
    if (isLoadingStaff) return;

    if (observer?.current) observer?.current.disconnect();

    let callback = function (
      entries
      //observer
    ) {
      if (
        entries[0].isIntersecting &&
        nextPage &&
        staffsData?.data?.results?.length > 0
      ) {
        setNeedToLoad(true);
      }
    };
    observer.current = new IntersectionObserver(callback);
    if (lastItem?.current instanceof Element) {
      observer.current.observe(lastItem.current);
    } else {
      console.warn("lastItem.current is not a valid DOM element");
    }
  }, [isLoadingStaff]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopover
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            dateAfterFilter={dateAfterFilter}
            dateBeforeFilter={dateBeforeFilter}
            // handleSetDateFilters={handleSetDateFilters}
            handleChangeDateBefore={handleChangeDateBefore}
            handleChangeDateAfter={handleChangeDateAfter}
          />

          {width > 640 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                style={{ borderRadius: "2px" }}
                prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                value={fileSearch}
                placeholder="Поиск по имени сотрудника"
                suffix={
                  <CloseOutlined
                    style={{
                      color: fileSearch ? "red" : "black",
                    }}
                    onClick={() => {
                      setFileSearch("");
                      setFiltersArray(
                        filtersArray.filter((v) => v.key !== "q")
                      );
                    }}
                  />
                }
                onChange={(evt) => {
                  setFileSearch(evt.target.value);
                  changeArrayFilters({
                    item: "",
                    key: "q",
                    value: evt.target.value,
                    text: evt.target.value,
                    title: "q",
                  });
                }}
              />
            </Space>
          )}

          <Space.Compact style={{ alignSelf: "flex-end" }} title="Фильтры">
            <Button
              onClick={() => {
                setOpenFiltersRow(!openFiltersRow);
              }}
              style={{
                borderRadius: "2px",
                width: width > 640 ? "151px" : "67px",
                gap: "20px",
              }}
              icon={
                <Badge count={filtersArray.length} size="small" color="blue">
                  <img
                    style={{
                      color: "#6679A9",
                    }}
                    alt="filters"
                    src={filters}
                  />
                </Badge>
              }
            >
              {width > 640 && "Фильтры"}
            </Button>
          </Space.Compact>

          {(dateAfterFilter ||
            dateBeforeFilter ||
            filtersArray?.length > 0) && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )}
        </div>

        {openFiltersRow && (
          <>
            {width < 640 && (
              <Space
                direction="vertical"
                style={{ width: "100%", paddingBottom: 25 }}
              >
                <Input
                  style={{ borderRadius: "2px" }}
                  prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                  value={fileSearch}
                  placeholder="Поиск по имени сотрудника"
                  suffix={
                    <CloseOutlined
                      style={{
                        color: fileSearch ? "red" : "black",
                      }}
                      onClick={() => {
                        setFileSearch("");
                        setFiltersArray(
                          filtersArray.filter((v) => v.key !== "q")
                        );
                      }}
                    />
                  }
                  onChange={(evt) => {
                    setFileSearch(evt.target.value);
                    changeArrayFilters({
                      item: "",
                      key: "q",
                      value: evt.target.value,
                      text: evt.target.value,
                      title: "q",
                    });
                  }}
                />
              </Space>
            )}
            <div
              style={{
                display: width > 543 ? "flex" : "grid",
                rowGap: width > 640 ? 12 : 22,
                columnGap: width > 640 ? 12 : 7,
                gridTemplateColumns: width < 543 ? "repeat(2, 50%)" : "1fr",
                position: "relative",
                paddingBottom: width > 640 ? 20 : 10,
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeFired)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Не уволенные"
                  options={fired.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                  style={{ width: "100%" }}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeFired, setIncludeFired)
                  }
                  value={selectedFired}
                  onChange={setSelectedFired}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeChain)}
                <Select
                  allowClear={true}
                  maxTagCount="responsive"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Сетевые"
                  value={selectedChain}
                  onChange={setSelectedChain}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeChain, setIncludeChain)
                  }
                  options={chain.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includePosition)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Должность"
                  style={{ width: "100%", textAlign: "left" }}
                  options={positionList?.map((option) => {
                    return {
                      value: option.pk,
                      label: option.y_title,
                    };
                  })}
                  mode="multiple"
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includePosition, setIncludePosition)
                  }
                  value={selectedPosition}
                  onChange={setSelectedPosition}
                />
              </Space>
            </div>
          </>
        )}
      </>

      <div
        style={{
          maxHeight: "calc(100vh - 300px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(error || errorStudio) && displayErrors(error || errorStudio)}
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={(isLoadingStudio || isLoadingStaff) && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={staffsData?.data?.results || []}
          onRow={(record) => ({
            onClick: () => {
              sessionStorage.setItem("user", JSON.stringify(record));
              navigate(`/staff_edit/${studioData?.y_id}`);
            },
          })}
        />
        <div ref={lastItem} style={{ minHeight: 10, maxHeight: 10 }} />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <p style={{ margin: 0 }}>Найдено:</p>
        <div
          style={{
            border: "1px solid #4B4A4A33",
            backgroundColor: "#FFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 25,
            fontSize: 12,
            fontWeight: 300,
            padding: "0 15px",
          }}
        >
          {staffsData?.data?.count || 0}
        </div>
      </div>
      {openStaffDelete.open && (
        <ModalComponent
          open={openStaffDelete.open}
          title={
            <>
              Вы уверены, что хотите удалить этого{" "}
              <span style={{ color: "#8B8B8B" }}>
                {openStaffDelete?.data?.y_name}
              </span>{" "}
              сотрудника?
            </>
          }
          body={
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                  }}
                  onClick={() => {
                    setOpenStaffDelete(false);
                    dispatch(clearErrorDelete());
                  }}
                >
                  Не удалять
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                    background: "var(--Primary-1-normal, #2E496F)",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(clearErrorDelete());
                    dispatch(
                      deleteStaff({
                        studio_y_id: studioData?.y_id,
                        id: openStaffDelete.data.id,
                      })
                    ).then(() => {
                      dispatch(clearSuccess());
                      setOpenStaffDelete(false);
                    });
                  }}
                >
                  Удалить
                </Button>
              </Col>
            </Row>
          }
          onCancel={() => {
            dispatch(clearErrorDelete());
            setOpenStaffDelete(false);
          }}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по сотрудникам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
