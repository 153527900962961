import { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import { Button, Table, Spin, Row, Col, Tooltip } from "antd";
import { ModalComponent } from "../../components/ModalComponent";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import { getStudioData } from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getType,
  deleteType,
  clearError,
  clearSuccessDelete,
} from "../../store/typeComplaints";
import {
  getError,
  getTypeData,
  getSuccessDelete,
  getSuccessEdit,
  getSuccessCreate,
  getTypeLoading,
  getOpenTypeSelector,
  getTypeNextPage,
} from "../../store/typeComplaints/selectors";
import displayErrors from "../../utils/displayErrors";
import { getColorAndText } from "../../utils/utils";
import { CraeteTypeModal } from "../../components/CraeteTypeModal";
import { EditTypeModal } from "../../components/EditTypeModal";

export const TypesComplaints = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const typeData = useSelector(getTypeData);
  const [width, setWidth] = useState(window.innerWidth);
  const isLoading = useSelector(getTypeLoading);
  const successCreate = useSelector(getSuccessCreate);
  const successEdit = useSelector(getSuccessEdit);
  const successDelete = useSelector(getSuccessDelete);
  const openTypeCreate = useSelector(getOpenTypeSelector);
  const openInfo = useSelector(getOpenInfoSelector);
  const [openTypeEdit, setOpenTypeEdit] = useState(false);
  const [openTypeDelete, setOpenTypeDelete] = useState(false);
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getTypeNextPage);
  const lastItem = useRef();
  const observer = useRef();

  const columns = [
    createColumn(
      "Тип жалобы",
      "name",
      (v) => {
        return <span style={{ color: "#4B4F55" }}>{v}</span>;
      },
      450
    ),
    createColumn(
      "Критичность",
      "critical_level",
      (v) => {
        return <span>{getColorAndText(v)}</span>;
      },
      450
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>Удалить тип жалоб</span>}
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenTypeDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      20
    ),
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  const fetchData = (pagination = false) => {
    let limitValue = null;

    if (pagination && nextPage) {
      const urlParams = new URLSearchParams(nextPage.split("?")[1]);
      limitValue = Number(urlParams.get("limit")) + 25;
    }

    dispatch(
      getType({
        studio_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        pagination: pagination,
      })
    );
  };

  useEffect(() => {
    dispatch(clearErrorStudio());
    dispatch(clearError());
  }, []);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [studioData, successCreate, successEdit, successDelete]);

  useEffect(() => {
    if (isLoading) return;

    if (observer?.current) observer?.current.disconnect();

    let callback = function (
      entries
      //observer
    ) {
      if (
        entries[0].isIntersecting &&
        nextPage &&
        typeData?.data?.results?.length > 0
      ) {
        setNeedToLoad(true);
      }
    };
    observer.current = new IntersectionObserver(callback);
    if (lastItem?.current instanceof Element) {
      observer.current.observe(lastItem.current);
    } else {
      console.warn("lastItem.current is not a valid DOM element");
    }
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <div
        style={{
          maxHeight: "calc(100vh - 300px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {error && displayErrors(error)}
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={isLoading && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={typeData?.data?.results || []}
          onRow={(record) => ({
            onClick: () => {
              setOpenTypeEdit({
                open: true,
                record: record,
              });
            },
          })}
        />
        <div ref={lastItem} style={{ minHeight: 10, maxHeight: 10 }} />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <p style={{ margin: 0 }}>Найдено:</p>
        <div
          style={{
            border: "1px solid #4B4A4A33",
            backgroundColor: "#FFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 25,
            fontSize: 12,
            fontWeight: 300,
            padding: "0 15px",
          }}
        >
          {typeData?.data?.count || 0}
        </div>
      </div>
      {openTypeCreate && <CraeteTypeModal openTypeCreate={openTypeCreate} />}
      {openTypeEdit && (
        <EditTypeModal
          openTypeEdit={openTypeEdit}
          setOpenTypeEdit={setOpenTypeEdit}
        />
      )}
      {openTypeDelete.open && (
        <ModalComponent
          open={openTypeDelete.open}
          title={`Вы уверены, что хотите удалить этот тип "${openTypeDelete?.data?.name}"?`}
          body={
            <>
              {error && displayErrors(error)}
              <Row gutter={20}>
                <Col span={12}>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: 4,
                      height: 48,
                    }}
                    onClick={() => setOpenTypeDelete(false)}
                  >
                    Не удалять
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: 4,
                      height: 48,
                      background: "var(--Primary-1-normal, #2E496F)",
                    }}
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      dispatch(
                        deleteType({
                          studio_y_id: studioData?.y_id,
                          id: openTypeDelete.data.id,
                        })
                      ).then((state) => {
                        if (!state?.payload?.errors) {
                          dispatch(clearSuccessDelete());
                          setOpenTypeDelete(false);
                        }
                      });
                    }}
                  >
                    Удалить
                  </Button>
                </Col>
              </Row>
            </>
          }
          onCancel={() => {
            setOpenTypeDelete(false);
          }}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по жалобам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
