import { useEffect, useState, useRef } from "react";
import { Table, Spin, Tooltip, Col, Row, Button, Tag, Avatar } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { UserModal } from "../../components/UserModal";
import { ModalComponent } from "../../components/ModalComponent";
import displayErrors from "../../utils/displayErrors";
import { getStudioData } from "../../store/studios/selectors";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettingsComplaints,
  clearSuccessDelete,
  deleteUserManagement,
} from "../../store/userManagement";
import {
  getUserManagementData,
  getOpenUserManagementSelector,
  getUserManagementLoading,
  getSuccessDelete,
  getSuccessPatch,
} from "../../store/userManagement/selectors";
import { translateWord } from "../../utils/utils";

export const UserManagement = () => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [filtersArray, setFiltersArray] = useState([]);
  const [needToLoad, setNeedToLoad] = useState(false);
  const [openUserManagementDelete, setOpenUserManagementDelete] =
    useState(false);
  const studioData = useSelector(getStudioData);
  const userManagementData = useSelector(getUserManagementData);
  const openUserManagement = useSelector(getOpenUserManagementSelector);
  const patchSuccess = useSelector(getSuccessPatch);
  const successDelete = useSelector(getSuccessDelete);
  // const nextPage = useSelector(getUserNextPage);
  const isLoading = useSelector(getUserManagementLoading);
  const lastItem = useRef();
  const observer = useRef();

  const columns = [
    createColumn(
      "Имя",
      "y_name",
      (v, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={row?.y_avatar}
            />
            <span>{v}</span>
          </div>
        );
      },
      70
      // "center"
    ),
    createColumn(
      "Контакты",
      "y_phone",
      (_, v) => {
        return (
          <Row style={{ flexDirection: "column", justifyContent: "center" }}>
            <Col style={{ fontSize: "12px" }}>
              {v?.y_phone.slice(0, 3) + "****" + v?.y_phone.slice(-4)}
            </Col>
            <Col style={{ fontSize: "12px" }}>{v?.y_email}</Col>
          </Row>
        );
      },
      70
      // "center"
    ),
    createColumn(
      "Роль пользователя",
      "role",
      (v) => {
        return (
          <Tag
            color={
              {
                worker: "#ffe7ba",
                administrator: "#bae0ff",
                manager: "#b5f5ec",
                owner: "#d9f7be",
                system: "#ffccc7",
              }[v]
            }
            style={{
              fontSize: 12,
              lineHeight: "16px",
              textAlign: "center",
              textTransform: "uppercase",
              margin: 0,
              color: {
                worker: "#d46b08",
                administrator: "#0958d9",
                manager: "#08979c",
                owner: "#389e0d",
                system: "#cf1322",
              }[v],
            }}
          >
            {translateWord(v)}
          </Tag>
        );
      },
      70
      // "center"
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={
              <span style={{ color: "#4B4F55" }}>Удалить пользователя</span>
            }
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenUserManagementDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      100,
      "right"
    ),
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  const fetchData = (pagination = false) => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    // if (dateAfterFilter || dateBeforeFilter) {
    //   filters = {
    //     start_date: convertDateToISO861(dateAfterFilter),
    //     end_date: convertDateToISO861(dateBeforeFilter),
    //   };
    // }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   limitValue = Number(urlParams.get("limit")) + 25;
    // }

    dispatch(
      getSettingsComplaints({
        studio_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        filters: filters,
        filtersArray: filtersArray,
        // cursor: pagination ? offsetValue : null,
        limit: pagination ? limitValue : null,
        pagination: pagination,
      })
    );
  };

  // useEffect(() => {
  //   (async () => {
  //     if (needToLoad && nextPage) {
  //       fetchData(true);
  //       setNeedToLoad(false);
  //     }
  //   })();
  // }, [needToLoad]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    // dateAfterFilter,
    // dateBeforeFilter,
    filtersArray,
    studioData,
    successDelete,
    patchSuccess,
    // successCreate,
    // successEdit,
  ]);

  // useEffect(() => {
  //   if (isLoadingFails) return;

  //   if (observer?.current) observer?.current.disconnect();

  //   let callback = function (
  //     entries
  //     //observer
  //   ) {
  //     if (
  //       entries[0].isIntersecting &&
  //       nextPage &&
  //       failsData?.data?.results?.length > 0
  //     ) {
  //       setNeedToLoad(true);
  //     }
  //   };
  //   observer.current = new IntersectionObserver(callback);
  //   if (lastItem?.current instanceof Element) {
  //     observer.current.observe(lastItem.current);
  //   } else {
  //     console.warn("lastItem.current is not a valid DOM element");
  //   }
  // }, [isLoadingFails]);

  return (
    <>
      <p className={styles.text}>
        Список пользователей, которые имеют доступ к просмотру данных студии в
        сети
      </p>
      <div
        style={{
          maxHeight: "calc(100vh - 300px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* {error && displayErrors(error)} */}
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={isLoading && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={
            userManagementData?.flatMap((item) => item?.users_with_access) || []
          }
          // onRow={(record) => ({
          //   onClick: () => {
          //     setOpenFailsEdit({
          //       open: true,
          //       record: record,
          //     });
          //   },
          // })}
        />
        <div ref={lastItem} style={{ minHeight: 10, maxHeight: 10 }} />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <p style={{ margin: 0 }}>Найдено:</p>
        <div
          style={{
            border: "1px solid #4B4A4A33",
            backgroundColor: "#FFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 25,
            fontSize: 12,
            fontWeight: 300,
            padding: "0 15px",
          }}
        >
          {userManagementData?.flatMap((item) => item?.users_with_access)
            ?.length || 0}
        </div>
      </div>
      {openUserManagement && (
        <UserModal isModalOpen={openUserManagement} data={userManagementData} />
      )}
      {openUserManagementDelete.open && (
        <ModalComponent
          open={openUserManagementDelete.open}
          title={`Вы уверены, что хотите удалить этого пользователя '${openUserManagementDelete?.data?.y_name}'?`}
          body={
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                  }}
                  onClick={() => setOpenUserManagementDelete(false)}
                >
                  Не удалять
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                    background: "var(--Primary-1-normal, #2E496F)",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(
                      deleteUserManagement({
                        studio_y_id: studioData?.y_id,
                        id: userManagementData[0]?.id,
                        user_id: openUserManagementDelete?.data?.id,
                      })
                    ).then(() => {
                      dispatch(clearSuccessDelete());
                      setOpenUserManagementDelete(false);
                    });
                  }}
                >
                  Удалить
                </Button>
              </Col>
            </Row>
          }
          onCancel={() => {
            setOpenUserManagementDelete(false);
          }}
        />
      )}
    </>
  );
};
